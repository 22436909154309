<template>
  <div class="main-content q-pa-md">
    <Mural :notificationQuantity="listCompanies.length" route="home" :query="{  }"
     :breadcrumbs="breadcrumbs">
      <DefaultBtn v-show="validateActionsPage('company-add')" titleButton="Adicionar empresa" icon="add" route="new-company"/>
    </Mural>

    <main class="q-ma-sm">
      <div class="content-table row flex">
        <div class="column col-12 q-pt-lg">
          <Table
              class="full-height"
              :list="listCompanies"
              :columns="columnsTable"
              :addEdit="validateActionsPage('company-edit')" 
              :addRemove="validateActionsPage('company-delete')"               
              @disabled="(company) => disabled(company)"
              @edit="(company) => edit(company)"
          ><template v-slot:body="props">
              <div>
                <q-btn
                  v-if="validateActionsPage('company-edit')"
                  dense
                  round
                  color="default-pink"
                  title="Editar"
                  flat
                  @click="() => edit( props.props.row)"
                  icon="o_edit_note"
                />
                <q-btn
                  v-if="validateActionsPage('company-delete')"
                  dense
                  round
                  color="default-pink"
                  title="Remover"
                  flat
                  @click="() => disabled( props.props.row)"
                  icon="o_delete_outline"
                />
              </div>
            </template>
          </Table>

        </div>
      </div>
    </main>
     <q-inner-loading
      :showing="loadingPage"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    >
      <div
        class="collumn justify-center q-my-md"
      >
        <div style="height: auto; width: 50px; margin: auto">
           <LoadingIcon  />
        </div>
        <label>Por favor aguarde...</label>
      </div>
    </q-inner-loading>
  </div>
</template>

<script>
import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import Table from "@/components/shared/tables/TableDynamic.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";

import CanUserAccessClass from "@/utils/CanUserAccessClass";

import {ref, onMounted} from "vue";
import CompanyServices from "@/services/CompanyServices";
import { useQuasar } from "quasar";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "Company",
  components: {
    Mural,
    DefaultBtn,
    Table,
    LoadingIcon
  },
  setup() {
    let breadcrumbs = ref(["Empresas"]);
    let listCompanies = ref([]);
    let router = new useRouter();
    let companyServices = new CompanyServices();
    let loadingPage = ref(false);
    let $q = new useQuasar();
    let columnsTable = ref([
      {
        name: 'company_name',
        required: true,
        label: 'Nome',
        align: 'left',
        field: row => row.company_name,
        sortable: true
      },
      {name: 'phone', align: 'left', label: 'Telefone', field: 'phone', sortable: false},
      {name: 'cnpj', align: 'left', label: 'CNPJ', field: 'cnpj', sortable: false},
      {name: 'acao', align: 'center', label: 'Ações', field: 'acoes', sortable: false},
    ])
    let canUserAccessClass = new CanUserAccessClass();
    let userData = ref({});
    const store = useStore();

    async function listAllCompanies() {
      loadingPage.value = true
      companyServices.getAll()
        .then((response) => {
          listCompanies.value = response.data;
          loadingPage.value = false
        }).catch((error) => {
          alertErro(error.response.data.toString());
          loadingPage.value = false
        });
    }

    function validateActionsPage(routeName) {
     
      if(store.state.userdata.master == 1){
        return true;
      }else if (!canUserAccessClass.canUserAccess(routeName, false)) {
        return false;
      }
      return true;
    }

    function disabled(company){
        $q.notify({
        message: `Deseja remover a empresa ${company.company_name}`,
        color: "red-9",
        position: "bottom",
        actions: [
          {
            label: "Remover",
            color: 'white',
            handler: async () => {
              await removeCompany(company.id)
            },
          },
        ],
      });
    }

    async function removeCompany(companyId){
      loadingPage.value = true;
      await companyServices.disabled(companyId).catch((error) => {
        alertErro(error.response.data.toString());
        loadingPage.value = false
      });

      listCompanies.value = listCompanies.value.filter(company => {
        return company.id != companyId;
      });
      loadingPage.value = false;
    }

    function edit(company){
       router.push({ name: "new-company", query: { companyId: company.id } });
    }


    function alertErro(message){
        $q.notify({
              message: message,
              color: "red-9",
              position: "top",
            });
            loading.value = false;
    }

    onMounted(() => {
      listAllCompanies();
    })

    return {
      breadcrumbs,
      listCompanies,
      loadingPage,
      columnsTable,
      disabled,
      edit,
      validateActionsPage
    }
  },

}
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;

  .lesson-content {
    width: 100%;
    height: 75vh;
    overflow-y: auto;
  }
}
.content-table {
  height: 80vh;
}
</style>